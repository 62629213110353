.SectionBlog {

}

.SectionBlog .section-title {
    margin-bottom: 50px;
}

.SectionBlog .section-content {

}

.SectionBlog .section-content .blog-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-right: -20px;
}

.SectionBlog .section-content .blog-list .blog-item {
    flex-basis: calc(33.33333% - 20px);
    min-width: calc(33.33333% - 20px);
    max-width: calc(33.33333% - 20px);
    background-color: rgb(255, 255, 255);
    padding: 0px;
    box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
    padding-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
}

.SectionBlog .section-content .blog-list .blog-item:hover .info h3 {
    color: #E65425!important;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .image {
    height: 280px;
    background-color: red;
    margin-bottom: 20px;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0px;
    padding: 0px 18px;
    margin-bottom: 8px;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author p {
    color: #6b7385c5;
    display: flex;
    gap: 10px;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author p i {
    color: #E65425;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author .author-container {
    display: flex;
    align-items: center;
    gap: 10px;

}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author .author-container .avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author .author-container .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .author p {
    margin: 0;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .info {
    padding: 0px 18px;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .info h3 {
    font-size: 22px;
    margin: 5px 0;
    color: #2e2e2e;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 12px 0px;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard .info p {
    font-size: 15px;
    line-height: 1.7;
    font-weight: 500;
    margin: 0 0 15px;
    color: #6B7385;
}

.SectionBlog .section-content .blog-list .blog-item .BlogCard a {
    padding: 0px 18px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #E65425;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
}
