.Footer {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
}

.Footer .footer-info {
    flex-basis: 35%;
    flex-shrink: 1;
    min-width: 35%;
    padding-right: 4%;
}
.logo > img{
    width: 50%;
}
.Footer .footer-info .logo {
    margin-bottom: 24px;
}

.Footer .footer-info p {
    color: #6B7385;
    margin-bottom: 30px;
}

.Footer .footer-info ul {
    margin-bottom: 24px;
    list-style: none;
    display: flex;

    justify-content: flex-start;
    gap: 16px;
    padding: 0;
}

.Footer .footer-info ul li {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition:  0.38s ease-in-out transform, border-radius 0.1s ease-in-out;
}

.Footer .footer-info ul li:hover {
    transform: rotateZ(360deg) scale(1.2);
    border-radius: 50%;
    background-color: #E65425;
}

.Footer .footer-info ul li:hover a {
    color: white;
}

.Footer .footer-info ul li a {
    color: #6B7385;
}

.Footer .footer-info button {
    background: #2E2E2E;
    color: #fff;
    padding: 10px 18px;
    transition: all 0.5s;
    border: none;
    border-radius: 6px;
    border: 1px solid white;
}


.Footer .footer-info button:hover {
    border: 1px solid #2E2E2E;
    background-color: white;
    color: #2E2E2E;
}

.Footer .footer-services {
    flex-basis: 65%;
    min-height: 300px;
    display: flex;
}

.Footer .footer-services .service {
    flex-basis: 25%;
    min-width: 25%;
    max-width: 25%;
    flex: 0 0 auto;
    padding: 0px 12px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.Footer .footer-services .title {
    flex-basis: 15%;
    position: relative;
}

.Footer .footer-services .title h2::before {
    content: "";
    width: 40px;
    height: 3px;
    background-color: #E65425;
    position: absolute;
    bottom: 0;
    border-radius: 2px;
}

.Footer .footer-services  .service .title h2 {
    margin: 0;
    color: #1B1B1B;
    font-size: 18px;
    font-weight: 600;
    position: relative;
}


.Footer .footer-services .title h2 {
    color: #1B1B1B;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
}

.Footer .footer-services .service .content ul{
    list-style: none;
    padding: 0;
}

.Footer .footer-services .service .content ul li {
    color: #6B7385;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.12s;
}

.Footer .footer-services .service .content ul li:hover {
    letter-spacing: 1.2px;
    color: #E65425;
}

.Footer .footer-services .service .content ul li i {
    margin-right: 6px;
    font-size: 12px;
    font-weight: normal;
   
}

.Footer .footer-bottom {
    flex-basis: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ddd;
}

.Footer .footer-bottom p {
    color: #6B7385;
    font-size: 16px;
    margin: 0;
}