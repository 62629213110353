.SectionAboutReview {
    display: flex;
    gap: 80px;
}

.SectionAboutReview .section-images {
    flex-basis: 45%;
    min-width: 45%;
}

.SectionAboutReview .work-set-image {
    display: flex;
    gap: 10px;
}

.SectionAboutReview .work-set-image .work-set img {
    height: 100%;
    object-fit: cover;
}

.SectionAboutReview .work-set-image .mutil-img{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    gap: 20px;
}

.SectionAboutReview .work-set-image .mutil-img img {
    background-color: rebeccapurple;
    flex-grow: 1;
    margin: 0!important;
    border-radius: 6px;
}

.SectionAboutReview .section-content {

}

.SectionAboutReview .section-content h2{
    color: #2E2E2E;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px;
}
.SectionAboutReview .section-content p{
    color: #515151;
    font-weight: 500;
    margin-bottom: 20px;
}
.SectionAboutReview .section-content .demand-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.SectionAboutReview .section-content .demand-list .demand-item {
    width: 100%;
    display: flex;
}

.SectionAboutReview .section-content .demand-list .demand-content p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    color: #6B7385;
}

.SectionAboutReview .section-content .demand-list .demand-content h6{
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;

}

.SectionAboutReview .section-content .demand-list .demand-image {
    margin-right: 20px;
    flex-shrink: 0;
    width: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}








