.SectionEmployer {
    height: fit-content;
}

.SectionEmployer .section-title {
    margin-bottom: 50px;
}

.SectionEmployer .section-content {

}


.SectionEmployer .section-content .project-list {
    display: flex;
    gap: 20px;
}

.SectionEmployer .section-content .project-item {
    flex-basis: 25%;
    height: 200px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 30px;
    border-radius: 8px;
    border-top: 2px solid #E65425;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
}

.SectionEmployer .section-content .project-item .feature-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SectionEmployer .section-content .project-item .feature-content .counter-up {
    font-size: 28px;
    font-weight: 600;
}

.SectionEmployer .section-content .project-item .feature-content p {
    font-size: 16px;
    color: #6B7385;
    margin: 0;
}