.SectionProject .section-title {
    margin-bottom: 50px;
}

.SectionProject .section-content  {
    width: 300%;
}

.SectionProject .section-content .project-list {
    width: 100%;
}

.SectionProject .section-content .project-list .project-list-inner {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-right: -20px;
}

.SectionProject .section-content .project-list .project-list-inner .project-card {
    flex-basis: calc(25% - 20px);
    flex-shrink: 1;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    height: 350px;
    padding: 14px;
    border: 1px solid #ddd;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    cursor: pointer;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .image {
    flex-grow: 1;
    flex-shrink: 1;
    background-color: rebeccapurple;
    border-radius: 6px;
    position: relative;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .image .technology {
    position: absolute;
    background-color: white;
    font-size: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    left: 10px;
    top: 10px;
    color: #6d6b6b;
    transition: 0.18s ease-in-out;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard:hover .image .technology{
    background-color: #E65425;
    color: white;

}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info {
    display: flex;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info .count {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}   

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info .count p {
    margin: 0;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info .count p:nth-child(1) {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    width: fit-content;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info .line {
    width: 1px;
    height: 100%;
    background-color: #ddd;
}

.SectionProject .section-content .project-list .project-list-inner .project-card .ProjectCard .info .count p:nth-child(2) {
    opacity: 0.8;
    font-size: 14px;
}

