.FilterSide {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .filter-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .filter-title h4 {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
  
  .reset-filters {
    background-color: #ff6b6b;
    border: none;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .reset-filters:hover {
    background-color: #e85656;
  }
  
  .price-filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .price-inputs {
    display: flex;
    gap: 10px;
  }
  
  .price-inputs input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .price-inputs input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .price-filter h5 {
    margin: 0;
    font-size: 1em;
    color: #555;
  }
  .location-filter {
    max-height: 150px; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ddd; /* Add a border to the dropdown */
    border-radius: 4px; /* Round the corners */
    background-color: #fff; /* Set the background color */
    padding: 5px; /* Add some padding */
  }
  
  /* Additional styling for the individual options */
  .location-option {
    padding: 8px; /* Add some padding for each option */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .location-option:hover {
    background-color: #f0f0f0; /* Change background on hover */
  }
  
  