.widget-box {
  padding: 20px;
}

.title-box {
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(142, 141, 208, 0.12);
  border-radius: 5px;
  border: 1px solid #f4f7ff;
}

/*-----------------
	6. Focus Label
-----------------------*/

.form-focus {
  height: 58px;
  position: relative;
}
.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -3px;
  z-index: 1;
  color: #8c635b;
  margin-bottom: 0;
}
.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control {
  height: 58px;
  padding: 21px 12px 6px;
  border: 1px solid #ffe6e1;
}
.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}
.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}
.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

.cal-icon {
  position: relative;
}

.form-control {
  border-color: #e7e8ea;
  color: #353535;
  font-size: 15px;
  min-height: 40px;
  padding: 6px 15px;
  border-radius: 5px;
}

.hours-rate-img {
  border: 1px solid #ced4e6;
  margin-right: 10px;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.hours-rate-img {
  border: 1px solid #ced4e6;
  margin-right: 10px;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}
.hours-rate-img h6 {
  margin: 0;
}
.customize-radio input {
  display: none;
}
.success-check {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}
.active .hours-rate-img {
  border: 1px solid #34c759;
}
.active .success-check {
  display: block;
}
.hours-rate-img img {
  margin-bottom: 5px;
}

.attach-file {
  background: #e65425;
  display: inline-flex;
  padding: 7px 15px;
  color: #fff;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.attach-file input {
  position: absolute;
  left: 0;
  opacity: 0;
}
.buget-img ul {
  list-style: none;
  display: flex;
}
