.SectionDev {
   
}

.SectionDev .section-title {
    margin-bottom: 50px;
}


.SectionDev .owl-carousel {
    position: relative;
    height: 435px;
}

.SectionDev  .owl-carousel .owl-item {
    height: 100%;
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.SectionDev  .owl-carousel .owl-stage-outer,.owl-stage {
    height: 100%;
}

.SectionDev .owl-nav {
    position: absolute;
    top: -80px;
    right: 0;
}

.SectionDev .owl-nav button {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
}

.SectionDev .owl-nav button:hover {
    background-color: #E65425!important;
}

.SectionDev .owl-nav  i span {
    display: none!important;
}

.SectionDev .owl-nav  i  {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    border: 1px solid #ddd;
}

.SectionDev .DevCard {
    width: 100%;
    height: 100%;
}

.SectionDev .owl-carousel .item {
    height: 100%;
}

.SectionDev .DevCard .dev-card-container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.SectionDev .DevCard .dev-card-container .box-color {
    width: 100%;
    height: 20%;
    background-color: #FFF6F1;
    border-radius: 4px;
    margin-bottom: 80px;
}

.SectionDev .DevCard .dev-card-container .avatar {
    width: 106px;
    height: 106px;
    position: absolute;
    top: 10%;
    left: calc(50% - 53px); 
}

.SectionDev .DevCard .dev-card-container .avatar .status {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    background-color: #6B7385;
    border-radius: 50%;
    bottom: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: 4px solid white;
    background-color: #159C5B;
}

.SectionDev .DevCard .dev-card-container .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.SectionDev .DevCard .dev-card-container .info {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.SectionDev .DevCard .dev-card-container .info p {
    margin: 0;
    text-align: center;
}


.SectionDev .DevCard .dev-card-container .info p:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}


.SectionDev .DevCard .dev-card-container .info p:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: #6B7385;
    margin-bottom: 5px;
    margin: 0;
}

.SectionDev .DevCard .dev-card-container .info p:nth-child(3) {
    display: flex;
    gap: 4px;
    color: #6B7385;
    font-size: 14px;
    margin: 8px 0px;
}

.SectionDev .DevCard .dev-card-container .voting {

    display: flex;
    justify-content: center;
    gap: 4px;

}

.SectionDev .DevCard .dev-card-container .voting .bi-star-fill {
    color: #E65425;
}

.SectionDev .DevCard .dev-card-container .voting .bi-star-fill.grey {
    color: rgb(197, 196, 196);
}

.SectionDev .DevCard .dev-card-container .tags {
    display: flex;
    gap: 6px;
    justify-content: center;
    margin-top: 20px;
}

.SectionDev .DevCard .dev-card-container .tags .tag {
    font-size: 12px;
    background-color: #F4F7FF;
    padding: 4px 14px;
    border-radius: 6px;
    text-transform: capitalize;
}

.SectionDev .DevCard .dev-card-container .salary {
    display: flex;
    justify-content: center;
    font-weight: 600;
    padding: 20px 0px;
    justify-content: center;
}

.SectionDev .DevCard .dev-card-container .salary h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.SectionDev .DevCard .dev-card-container .view-profile {
    border: none;
    width: 100%;
    border: 1px solid #E65425;
    color: #E65425;
    background-color: white;
    flex-grow: 1;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 600;
}

.SectionDev .DevCard .dev-card-container .view-profile:hover {
    border: 1px solid white;
    background-color: #E65425;
    color: white;
} 


