.Header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  height: 74px;
}

.Header .navbar-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-right: 40px;
}

.Header .navbar-header .logo {
  cursor: pointer;
}

.Header .navbar-header .logo img {
  width: 30%;
  object-fit: contain;
}

.Header .navbar-main {
  height: 100%;
}

.Header .auth-header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.Header .auth-header .auth-button {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.2s ease-in-out;
  user-select: none;
}
.Header .auth-header .auth-button:hover {
  opacity: 1;
  color: #e65425;
}

.Header .auth-header i {
  font-size: 24px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Header .auth-header .line {
  height: 24px;
  width: 1px;
  background-color: #ddd;
}

.Header .auth-header .project-add {
  background-color: #e65425;
  padding: 6px 14px;
  border-radius: 6px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  margin-left: 10px;
  font-weight: 600;
}
