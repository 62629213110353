.About .section-bread-crumb {
    background-color: #FFF5F3;
}


.App .section-about-mission {
    padding: 80px 0;
}

.App .section-about-project {
    padding: 80px 0;
    background-color: #FEF7F4;
}

.App .section-about-review {
    padding: 80px 0;
}