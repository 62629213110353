.Home {

}

.Home .section-banner {
    background-image: url("../../../assets/images/banner1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 110vh;
}

.Home .section-banner .container {
    height: 100%;
}

.Home .section-categories {
    padding: 80px 0 50px;
    position: relative;
}

.Home .section-categories::before {
    position: absolute;
    top: calc(88% - 20px);
    width: 100%;
    height: 15%;
    background-color: #FFF8F5;
    content: "";
}

.Home .section-employer {
    background-color: #FFF8F5;
    padding-bottom: 65px;
}

.Home .section-review {
    padding: 80px 50px;
}

.Home .section-project {
    background-color: #FFF8F5;
    padding: 80px 50px;
}
.Home .section-developer {
    padding: 80px 50px;
}

.Home .section-subscribe {
    padding: 80px 50px;
    background-color: #081733;
    position: relative;
}

.Home .section-subscribe .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Home .section-subscribe .bg-img img{
    position: absolute;
    right: 0;
    top: calc(50% - 74px);
    height: 154px;
    width: 87px;
    object-fit: contain;
}


.Home .section-blog {
    padding: 80px 50px;
    position: relative;
}


.Home .section-job {
    padding: 80px 50px;
    background-color: #E65425;
    overflow: hidden;
}



