.BlogLatest {
    background-color: blue;
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid #e8e8e8;
    padding: 20px;
}


.BlogLatest .widget-main .title h4 {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: #161c2d;
    padding-bottom: 10px;
    margin-bottom: 15px;
}


.BlogLatest .widget-main .content .blog-list .blog-item {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.BlogLatest .widget-main .content .blog-list .blog-item:hover p:nth-child(1){
    color: #E65425;
}

.BlogLatest .widget-main .content .blog-list .blog-item .image {
    border-radius: 4px;
    overflow: hidden;
    flex-basis: 30%;
    min-width: 30%;
}

.BlogLatest .widget-main .content .blog-list .blog-item .image:hover img{
    transform: scale(1.1);
}

.BlogLatest .widget-main .content .blog-list .blog-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1.2s;
}

.BlogLatest .widget-main .content .blog-list .blog-item p {
    margin: 0;
}

.BlogLatest .widget-main .content .blog-list .blog-item p:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 0;
    margin-bottom: 8px;
}

.BlogLatest .widget-main .content .blog-list .blog-item p:nth-child(2) {
    color: #6e727d;
    font-size: 14px;
    display: flex;
    gap: 10px;
}