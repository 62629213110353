.SectionCategory {
    position: relative;
}



.SectionCategory .section-title {
    margin-bottom: 50px;
}

.SectionCategory .section-content {
    width: 100%;
    position: relative;

}


.SectionCategory .section-content .section-list-inner {
    width: calc(100% + 20px);
    height: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 50px;
}

.SectionCategory .section-content .section-work-inner {
    display: flex;
    gap: 20px;

}

.SectionCategory .section-content .section-work-inner .work-card {
    padding: 24px;
    background-color: white;
    min-height: 248px;
    box-shadow: 0px 16px 24px 7px rgba(142, 141, 208, 0.11);
    flex-grow: 1;
}

.SectionCategory .section-content .section-work-inner .work-card.bg-1 {
    background-image: url(../../../assets/images/icon/bg1.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}

.SectionCategory .section-content .section-work-inner .work-card.bg-2 {
    background-image: url(../../../assets/images/icon/bg2.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}

.SectionCategory .section-content .section-work-inner .work-card.bg-2 .content {
    background-image: url(../../../assets/images/icon/developer2.png)!important;
}



.SectionCategory .section-content .section-work-inner .work-card .content {
    height: 200px;
    background-image: url(../../../assets/images/icon/developer1.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}

.SectionCategory .section-content .section-work-inner .work-card .content .text {
    width: 55%;
}


.SectionCategory .section-content .section-work-inner .work-card .content p {
    font-weight: 500;
    font-size: 16px;
    color: #6B7385;
    margin-bottom: 24px;

    max-width: 100%;
}

.SectionCategory .section-content .section-work-inner .work-card .content .brown {
    padding: 10px 25px;
    color: white;
    background-color: #E65425!important;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
}
.SectionCategory .section-content .section-work-inner .work-card .content .brown:hover {
    filter: brightness(90%);
}

.SectionCategory .section-content .section-work-inner .work-card h2 {
    font-weight: bold;
    color: #1B1B1B;
    padding-bottom: 12px;
    position: relative;
    font-size: 28px;
}

.CateCard {
    width: 100%;
    flex-basis: 25%;
    flex-shrink: 1;
    min-width: calc(25% - 20px);
    max-width: calc(25% - 20px);
    min-height: 100px;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.28s ease-in-out;
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 10px;
}

.CateCard .info {
    display: flex;
    flex-direction: column;
}

.CateCard h5 {
    color: #1B1B1B;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    text-transform: capitalize;
    font-weight: 600;
}

.CateCard p {
    margin: 0;
    font-size: 14px;
    color: #6B7385;
    display: flex;
    align-items: center;
    display: flex;
    gap: 6px;
}

.CateCard i {
    font-size: 18px;
}

.CateCard .image {
    width: 66px;
    height: 66px;
    background-color: #FCE5DC;
    border-radius: 50%;
    padding: 6%;
}

.CateCard .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.CateCard:hover {
    border: 1px solid #E65425;
}