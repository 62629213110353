.ProjectCardView {
}

.ProjectCardView {
  height: 100%;
}

.ProjectCardView .dev-card-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProjectCardView .dev-card-container .box-color {
  width: 100%;
  height: 90px;
  background-color: #fff6f1;
  border-radius: 4px;
  margin-bottom: 80px;
}

.ProjectCardView .dev-card-container .avatar {
  width: 106px;
  height: 106px;
  position: absolute;
  top: 8%;
  left: calc(50% - 53px);
}

.ProjectCardView .dev-card-container .avatar .status {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  background-color: #6b7385;
  border-radius: 50%;
  bottom: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 4px solid white;
  background-color: #159c5b;
}

.ProjectCardView .dev-card-container .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ProjectCardView .dev-card-container .info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProjectCardView .dev-card-container .info p {
  margin: 0;
  text-align: center;
}

.ProjectCardView .dev-card-container .info p:nth-child(1) {
  font-size: 20px;
  color: #1b1b1b;
  font-weight: 600;
  text-transform: capitalize;
}

.ProjectCardView .dev-card-container .info p:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  color: #6b7385;
  margin-bottom: 5px;
  margin: 0;
}

.ProjectCardView .dev-card-container .info p:nth-child(3) {
  display: flex;
  gap: 4px;
  color: black;
  font-size: 14px;
  margin: 8px 0px;
  text-align: center;
}

.ProjectCardView .dev-card-container .voting {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.ProjectCardView .dev-card-container .voting .bi-star-fill {
  color: #e65425;
}

.ProjectCardView .dev-card-container .voting .bi-star-fill.grey {
  color: rgb(197, 196, 196);
}

.ProjectCardView .dev-card-container .tags {
  display: flex;
  gap: 6px;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.ProjectCardView .dev-card-container .tags .tag {
  font-size: 12px;
  background-color: #f4f7ff;
  padding: 4px 14px;
  border-radius: 6px;
  text-transform: capitalize;
}

.ProjectCardView .dev-card-container .salary {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 20px 0px;
  justify-content: center;
}

.ProjectCardView .dev-card-container .salary h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.ProjectCardView .dev-card-container .view-profile {
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  border-color: transparent;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #e65425;
  color: #e65425;
  padding: 9px 15px;
  font-weight: 700;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  font-size: 14px;
}

.ProjectCardView .dev-card-container .left {
  width: fit-content;

  border-color: transparent;
  text-transform: lowercase;
  background: transparent;
  color: #e65425;
  font-weight: 700;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: 10px;

  padding: 4px 8px;
  cursor: auto;
  opacity: 0.8;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 4px;
}

.ProjectCardView .dev-card-container .left i {
  position: relative;
  margin-right: 10px;
  font-size: 20px;
  height: fit-content;
}

.ProjectCardView .dev-card-container .view-profile:hover {
  border: 1px solid transparent;
  background-color: #e65425;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
