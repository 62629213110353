.UserLocation {
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid #e8e8e8;
    padding: 20px;
    box-shadow: 0px 8px 16px 0px #8E8DD01F;
    border-radius: 6px;
}


.UserLocation .widget-main .title h4 {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: #161c2d;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
