.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}
.page-wrapper {
  margin-left: 240px;
  padding: 60px 20px 0 0;
  position: relative;
  transition: all 0.4s ease;
}

.header .header-left {
  height: 60px;
  padding: 0 20px;
  position: absolute;
  width: 240px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff8f7;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.header .header-left .logo img {
  max-height: 45px;
  width: auto;
}

.sidebar {
  background-color: #fff8f7;
  bottom: 0;
  /* left: 0; */
  margin-top: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1001;
  overflow-y: hidden;
}

.sidebar-menu {
  padding: 60px 15px 0 15px;
}
