@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  overflow-x: hidden;
  min-width: 1500px;
}

input[type="checkbox"] {
  accent-color: #e65425;
}

.App {
  width: 100%;
  height: 100%;
  font-family: "Lato", sans-serif;
  position: relative;
}

.app-layout {
  width: 100%;
  position: relative;
  height: fit-content;
}

.app-header {
  position: sticky;
  width: 100%;
  z-index: 999;
  top: 0;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
}

.app-main {
  min-height: 100vh;
}

.app-footer {
  background-color: #fbfbfb;
  width: 100%;
  min-height: 200px;
}
