.BlogDetail {
}

.BlogDetail .section-bread-crumb {
  background-color: #fff5f3;
}

.BlogDetail .section-blog-detail {
  padding: 80px 0 30px;
}

.BlogDetail .section-blog-detail .main-flex {
  display: flex;
  gap: 24px;
  margin-bottom: 30px;
}

.BlogDetail .section-blog-detail .main-flex .blog-content {
  flex-grow: 1;
  max-width: 75%;
  flex-basis: 75%;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-image {
  width: 100%;
  margin-bottom: 30px;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-image img {
  width: 100%;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-name {
  margin-bottom: 15px;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-name h3 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-info {
  display: flex;
  margin-bottom: 20px;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}

.BlogDetail
  .section-blog-detail
  .main-flex
  .blog-content
  .blog-info
  .item-flex {
  display: flex;
  gap: 10px;
  color: #606060;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-info .author {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.BlogDetail
  .section-blog-detail
  .main-flex
  .blog-content
  .blog-info
  .author:hover
  p {
  color: #e65425;
}

.BlogDetail
  .section-blog-detail
  .main-flex
  .blog-content
  .blog-info
  .author
  .avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.BlogDetail
  .section-blog-detail
  .main-flex
  .blog-content
  .blog-info
  .author
  .avatar
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-info p {
  margin: 0;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-description {
  padding-right: 20px;
  margin-bottom: 80px;
}

.BlogDetail .section-blog-detail .main-flex .blog-content .blog-description p {
  font-size: 15px;
  margin: 0 0 20px;
  line-height: 1.7;
  font-weight: 500;
}

.BlogDetail .section-blog-detail .blog-list-filter {
  display: flex;
  height: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BlogDetail .section-blog-detail .blog-list-filter .custom-select-container {
  width: 200px;
}

.BlogDetail
  .section-blog-detail
  .blog-list-filter
  .custom-select-container
  .arrow {
  transform: translateY(2px);
}

.BlogDetail .section-blog-detail .blog-list-filter .custom-select-header {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.BlogDetail .section-blog-detail .blog-list-filter span {
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  display: inline-block;
  margin-bottom: 0;
}

.BlogDetail .section-blog-detail .blog-list-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: -20px;
}

.BlogDetail .section-blog-detail .filter-side {
  flex-basis: 25%;
  flex-shrink: 1;
  min-width: 25%;
  max-width: 25%;
  position: relative;
}

.BlogDetail .section-blog-detail .filter-side .filter-sticky {
  height: 100%;
  width: 100%;
  height: auto;
  position: sticky;
  top: 12%;
}

.BlogDetail .section-blog-detail .comment-blog {
  min-height: 200px;
  margin-bottom: 30px;
}

.BlogDetail .section-blog-detail .comment-blog textarea:focus {
  outline: none !important;
  border: 1px solid #e65525b2;
  box-shadow: 0 0 10px #e65425;
}

.BlogDetail .section-blog-detail .comment-blog button {
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  min-width: 120px;
  background: #e65425;
  border: none;
  border-radius: 8px;
  color: white;
  margin-top: 25px;
}

.BlogDetail .section-blog-detail .comment-container {
}

.BlogDetail .section-blog-detail .comment-title {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  color: #161c2d;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.BlogDetail .section-blog-detail .comment-container .comment-list {
  width: 100%;
  padding: 10px 0px;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .image {
  flex-basis: 10%;
  min-width: 10%;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .image
  .avatar {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .image
  .avatar
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .content
  .reply-button {
  width: fit-content;
  display: flex;
  gap: 6px;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .content
  .comment-desc {
  width: 80%;
  font-size: 16px;
  color: #55545b;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .content
  .reply-button
  i {
  font-size: 24px;
}

.BlogDetail
  .section-blog-detail
  .comment-container
  .comment-list
  .comment-item
  .content
  .commentor-info {
  color: #161c2d;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 700;
}

.BlogCategories {
  box-shadow: none;
}

.BlogTags {
  box-shadow: none;
}
