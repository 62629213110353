.BlogCategories {
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid #e8e8e8;
    padding: 20px;
}


.BlogCategories .widget-main .title h4 {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: #161c2d;
    padding-bottom: 10px;
    margin-bottom: 15px;
}


.BlogCategories .category-link {
    list-style: none;
    padding: 0;
}

.BlogCategories .category-link li {
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;
}

.BlogCategories .category-link li:hover a {
    color: #E65425;
}

.BlogCategories .category-link li:hover a:after {
    color: #E65425;
}

.BlogCategories .category-link li a:after {
    display: inline-block;
    position: absolute;
    right: 0;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #2E3842;
}