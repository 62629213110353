.sidebar {
  background-color: #fff8f7;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 60px;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1001;
  overflow-y: hidden;
}
.table-top-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  align-items: center;
}
table .badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  min-width: 75px;
  padding: 4px 12px;
  text-align: center;
}

.badge-danger-lite {
  color: #ff3b30;
  background: #fff2f2;
}
.badge-paid {
  color: #34c759;
  background: #ebf9ee;
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  padding: 15px;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  position: relative;
}
.sidebar-menu li a {
  color: #131523;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 10px 15px;
}

.sidebar-menu li div {
  color: #131523;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 10px 15px;
}

.sidebar-menu > ul > li > div:hover {
  background-color: rgba(118, 56, 255, 0.05);
  color: #ff5b37;
  cursor: pointer;
}

.sidebar-menu li div:hover {
  cursor: pointer;
  color: #ff5b37;
}

.sidebar-menu li div:active {
  color: #ff5b37;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  border-radius: 5px;
}

.sidebar-menu li.active > div {
  color: #ff5b37;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  border-radius: 5px;
}

.sidebar-menu li a:hover {
  color: #ff5b37;
}
.sidebar-menu > ul > li > a:hover {
  background-color: rgba(118, 56, 255, 0.05);
  color: #ff5b37;
}
.sidebar-menu li.active > a {
  color: #ff5b37;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  border-radius: 5px;
}
.menu-title {
  color: #ff5b37;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.sidebar-menu li.menu-title a {
  color: #ff5b33;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  color: #ff5b37;
}
.mobile_btn {
  display: none;
  float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 45px;
  position: relative;
}
.sidebar-menu ul ul a span {
  float: right;
  color: #ff9800;
}
.sidebar-menu ul ul {
  display: none;
}
.sidebar-menu ul ul ul a {
  padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}
.sidebar-menu > ul > li {
  margin-bottom: 3px;
  position: relative;
}
.sidebar-menu > ul > li:last-child {
  margin-bottom: 25px;
}
.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
  -transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}
.sidebar-menu > ul > li > a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 5px;
}
.sidebar-menu > ul > li > a svg {
  width: 18px;
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}

.settings-widget {
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  margin-bottom: 30px;
}
.settings-header {
  background: #e65425;
  padding: 20px;
}
.settings-header h3,
.settings-header h3 a {
  color: #fff;
  font-size: 22px;
}
.settings-header p {
  color: #fff;
}
.settings-menu ul {
  display: block;
  border: 0;
  padding: 20px;
  margin: 0;
  list-style: none;
}
.settings-menu ul li + li {
  margin-top: 25px;
}
.settings-menu ul li a {
  color: #161c2d;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  border: 0 !important;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  line-height: normal;
  border-radius: 5px;
  transition: 0.5s all;
}
.settings-menu ul li a:hover,
.settings-menu ul li a:focus {
  color: #e65425;
  background-color: transparent !important;
  border: 0 !important;
}
.settings-menu ul li a.active {
  color: #e65425 !important;
  border: 0;
}
.sub-menu-ul li a.active {
  color: #e65425;
}
.settings-menu ul li a i {
  margin-right: 8px;
  font-size: 20px;
}
.plan-billing-section {
  padding-bottom: 40px;
}
.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #131523;
  margin-bottom: 5px;
  font-family: "Source Sans Pro", sans-serif;
}
.portfolio-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.dash-widget {
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.25);
  margin-bottom: 30px;
}
.dash-info {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.dash-widget-info {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
  color: #161c2d;
}
.dash-widget-count {
  font-weight: bold;
  font-size: 28px;
  color: #e65425;
  line-height: 1.2;
}
.dash-widget-more {
  padding: 20px;
}
.dash-widget-more a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
}
.plan-details h4 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
  color: #e65425;
}
.plan-details h3 {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1px;
  color: #e65425;
}
.plan-details .expiry {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 25px;
}
.yr-amt {
  font-size: 16px;
  color: #6b7385;
  margin-bottom: 10px;
}
.yr-duration {
  font-size: 14px;
}
.change-plan-btn .btn {
  padding: 11px 24px;
  border-radius: 6px;
}
.change-plan-btn {
  display: flex;
  gap: 14px;
}
.btn-primary-lite {
  background: #fff2f2;
  color: #e65425;
}
.btn-primary-lite:hover {
  background: #e65425;
  color: #fff;
}
.btn-plan {
  border-radius: 10px;
}
.plan-feature ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.plan-feature ul li {
  margin-bottom: 23px;
  position: relative;
  font-size: 14px;
  padding-left: 25px;
}
.plan-feature ul li:last-child {
  margin-bottom: 0;
}
.plan-feature ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #4b9f18;
  left: 0;
  margin-right: 5px;
  position: absolute;
  top: 0;
}
.plan-feature a {
  font-weight: 600;
  font-size: 14px;
  color: #e65425;
}
.on-project {
  border-bottom: 1px solid #f7f7f7;
  padding: 20px;
}
.on-project h5 {
  font-weight: 500;
  font-size: 16px;
  color: #e65425;
  margin-bottom: 5px;
}
.on-project p {
  font-weight: 500;
  font-size: 12px;
  color: #948e8e;
  margin-bottom: 10px;
}
.pro-info .list-details {
  margin-bottom: 0;
}
.pro-info .slot p {
  font-weight: 500;
  font-size: 12px;
  color: #161c2d;
  margin-bottom: 5px;
}
.pro-info .slot h5 {
  font-weight: 600;
  font-size: 13px;
  color: #838383;
}
.verify-box {
  border: 1px solid #e65425;
  border-radius: 2px;
  padding: 25px;
}
.verify-box img {
  margin-bottom: 20px;
}
.verify-box h5 {
  font-size: 20px;
  margin-bottom: 20px;
}
.verify-box a {
  font-weight: 600;
  color: #e65425;
  text-decoration: underline;
}
.chat-page footer {
  display: none;
}
.back-text {
  text-align: right;
  margin-bottom: 20px;
  padding-right: 25px;
}
.back-btn {
  padding: 5px 20px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
}
.fund-btn:hover {
  color: #fff;
}
.click-btn + .click-btn {
  margin-left: 10px;
}
.btn-secondary.click-btn {
  background: #f1f0fe;
  border: 1px solid transparent;
  color: #2a2760;
}
.btn-secondary.click-btn:hover {
  background: #747379;
  color: #fff;
}
label.file-upload.image-upbtn {
  background: #fff6f1;
  border-radius: 6px;
  color: #ee6e33;
  padding: 8px 24px;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0;
}
.trash-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  display: -webkit-box;
  border-radius: 50%;
  background: #ff3b30;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.trash-icon:hover {
  color: #fff;
  opacity: 0.8;
}
.avatar-medium {
  border-radius: 5px;
  width: 76px;
  height: 46px;
}
.pro-pic {
  margin-bottom: 0;
}
.pro-pic p {
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 0;
}
.light-pink-text {
  font-size: 12px;
  color: #f38247;
  padding-top: 10px;
}
.btn-list {
  background: rgba(255, 91, 55, 0.1);
  padding: 20px;
  border-radius: 5px;
}
.proedit-widget {
  background: #fff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 4px 14px 10px rgba(241, 241, 241, 0.3);
  border-radius: 5px;
  padding: 15px;
}
.proedit-header {
  padding-bottom: 40px;
}
.proedit-header h4 {
  font-weight: 500;
  font-size: 20px;
}
.proedit-body {
  list-style: none;
  padding: 0;
  margin: 0;
}
.proedit-body li {
  padding-bottom: 40px;
  display: -ms-flexbox;
  display: flex;
}
.proedit-body li span {
  color: #e65425;
  margin-right: 12px;
}
.proedit-widget .btn {
  height: 50px;
}
.small-text {
  font-size: 12px;
  color: #e65425;
  margin-bottom: 0;
}
label.award-upload.image-upbtn {
  border: 1px solid #f15c3d;
  border-radius: 5px;
  width: 76px;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #f15c3d;
}
label.award-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.del-modal i {
  color: #dfdfdf;
  margin-bottom: 35px;
}
.black-btn {
  background: #131135;
  border: 1px solid #131135;
}
.portfolio-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}
.portfolio-img .portfolio-live {
  transition: 0.5s ease;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  z-index: 99;
  color: #fff;
}
.portfolio-img .portfolio-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
}
.portfolio-img:hover .portfolio-live {
  opacity: 1;
  visibility: visible;
  background: rgba(255, 91, 55, 0.7);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.port-icon {
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #fff;
  border-radius: 50%;
  color: #e65425;
}
.portfolio-detail {
  text-align: center;
  padding: 30px;
}
.portfolio-detail .pro-name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  text-transform: none;
}
.hire-select .select2-container .select2-selection--single {
  background: #e65425;
  color: #fff;
  border-color: #e65425;
}
.hire-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
}
.hire-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #fff transparent transparent;
}
.br-0 {
  border-radius: 0 !important;
}
.port-title h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 35px;
}
.projects-list h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.prolist-details {
  display: flex;
  list-style: none;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  display: -ms-flexbox;
  padding: 0;
}
.prolist-details h5 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 5px;
  color: #6e727d;
  font-weight: 500;
}
.pro-overview h4 {
  font-size: 18px;
}
.pro-overview p {
  font-size: 15px;
  font-weight: 500;
}
.prolist-details p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.prolist-details li:not(:last-child) {
  margin-right: 40px;
}
.red-text {
  color: #ff0000;
}
.prolist-details .price {
  font-size: 23px;
  color: #f5365c;
  font-weight: 600;
  margin-bottom: 0;
}
.hire-box h6 {
  font-weight: bold;
  font-size: 14px;
  color: #e65425;
}
.hire-box p {
  font-weight: 600;
  font-size: 14px;
}
.btn-chat {
  font-weight: 500;
  font-size: 12px;
  color: #e65425;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 70px;
}
.bg-success-dark {
  background-color: #4b9f18;
  border: 1px solid #4b9f18;
  padding: 10px 15px;
  color: #fff;
  width: 80px;
  border-radius: 10px;
}
.bg-grey-dark {
  background-color: #808080;
  border: 1px solid #808080;
  padding: 10px 15px;
  color: #fff;
  width: 80px;
  border-radius: 10px;
}
.bg-pink-dark {
  background: #f5365c;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
}
.bg-grey-light {
  background: #dcdcdc;
  border-radius: 5px;
  padding: 10px 20px;
  color: #161c2d;
  border-radius: 10px;
}
.bg-outline-red {
  border: 1px solid #e65425;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: #e65425;
}
.bg-outline-green {
  border: 1px solid #4b9f18;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: #4b9f18;
}
.table .thead-pink th {
  font-size: 14px;
}
.table-box {
  background: #fff;
  border: 1px solid #e7e7e7;
}
.table-box div.dataTables_wrapper div.dataTables_info {
  padding-left: 15px;
  padding-bottom: 15px;
}
.table-box div.dataTables_wrapper div.dataTables_paginate {
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.bg-pink .input-group-text {
  background: #ffe6e1;
  border-color: #ffe6e1;
  color: #e65425;
}
.project-table h4 {
  font-weight: 600;
  font-size: 16px;
  color: #e65425;
  margin-bottom: 20px;
}
.project-table h3 {
  font-weight: 500;
  font-size: 24px;
  color: #161c2d;
  margin: 20px 20px 0;
}
.read-text {
  font-weight: 500;
  font-size: 14px;
  color: #e65425;
}
.br-30 {
  border-radius: 30px;
}
.file-circle {
  width: 33px;
  height: 33px;
  background: #fdf4f3;
  color: #e65425;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_length {
  padding: 20px 20px 5px;
}
.dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #e7e7e7;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.reply-box {
  padding: 20px;
  margin-bottom: 20px;
}
.reply-box h4 {
  margin-bottom: 0;
  color: #fff;
}
.dashboard-sec .pro-box {
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ced4e6;
  background: #fff;
}
.dashboard-sec .card-title {
  font-weight: 500;
}
.text-violet {
  color: #7b46be;
}
.text-pink {
  color: #fa6ca4;
}
.text-yellow {
  color: #facd3a;
}
.text-blue {
  color: #24c0dc;
}
.static-list {
  list-style: none;
  margin: 0;
}
.static-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.static-list li:last-child {
  margin-bottom: 0;
}
.earn-feature {
  border: 1px solid #f7f7f7;
  padding: 20px;
}
.earn-info p {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 600;
}
.earn-info .date {
  font-size: 14px;
  font-weight: 500;
}
.earn-img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.earn-img span img {
  margin-right: 15px;
}
.earn-img span {
  font-weight: 600;
  font-size: 14px;
}
.earn-feature .price {
  font-weight: 600;
  font-size: 24px;
  color: #e65425;
  margin-left: auto;
}
.pro-box {
  background: #fff;
  box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
  border-radius: 5px;
}
.action {
  align-items: center;
  display: inline-flex;
}
.rangslider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  background: #ffe6e1;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.rangslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 62px;
  height: 18px;
  background: #fff;
  border-radius: 5px;
  box-shadow: -100vw 0 0 100vw #e65425, 0px 4px 24px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.rangslider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.btn-approve {
  background: #00b47e;
  color: #fff;
  width: 115px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background: #ff5b37;
}
.nav-pills .nav-link {
  color: #ff5b37;
  border: 1px solid #ffe6e1;
  border-radius: 5px;
}
.reviews-menu-links {
  margin-top: 20px;
  margin-bottom: 20px;
}
.projects-card .nav-pills .nav-link.active,
.projects-card .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff5b37;
  font-weight: 500;
}
.projects-card .nav-pills .nav-link {
  border-radius: 5px;
  color: #333;
  border: 0;
  font-size: 16px;
}
.report-btns {
  text-align: right;
}
.table-btn {
  width: 205px;
  font-size: 12px;
  background: #ff5b37;
  color: #fff;
}
.table-btn:hover {
  background: #dd4b39;
  color: #fff;
}
.btn-blue {
  background: #131135;
  color: #fff;
}
.btn-enable {
  background: #131135;
  color: #fff;
  width: 122px;
}
.btn-disable {
  background: #ff0000;
  color: #fff;
  width: 122px;
}
.btn-enable:hover,
.btn-disable:hover {
  color: #fff;
  opacity: 0.8;
}
.table {
  color: #333;
  max-width: 100%;
  margin-bottom: 0;
  width: 100% !important;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order::before,
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order::before,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order::before,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order::before {
  position: absolute;
  bottom: 15px;
  content: "\2191";
  right: 10px;
  font-size: 18px;
  left: auto;
}
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  position: absolute;
  display: block;
  bottom: 20px;
  content: "\2193";
  right: 5px;
  font-size: 18px;
}
div.dt-container .dt-paging .dt-paging-button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 4px 10px;
  background: #fff !important;
  border: 1px solid #d5d5d5 !important ;
  border-right: 0 !important;
  margin: 0;
}
.dt-length label {
  margin-left: 5px;
}
div.dt-container .dt-paging .dt-paging-button.last {
  border-right: 1px solid #d5d5d5 !important;
}
.table > :not(:first-child) {
  border-top: transparent;
}
.table > :not(caption) > * > * {
  box-shadow: unset;
  padding: 0.75rem;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  font-size: 16px;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
  border-top: 0;
  padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table.dataTable {
  border-collapse: collapse !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2 a {
  color: #333;
}
table.table td h2 a:hover {
  color: #ff5b37;
}
table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
.table thead tr th {
  font-weight: 600;
  padding: 1rem;
}
.table tbody tr {
  border-bottom: 1px solid #dee2e6;
}
.table.table-center td,
.table.table-center th {
  vertical-align: middle;
}
.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
  color: #474648;
}
.table-striped thead tr {
  border-color: transparent;
}
.table-striped tbody tr {
  border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
  padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
  padding-right: 1.5rem;
}
.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}
.table .thead-light th {
  color: #495057;
  background-color: #f8f9fa;
  border-color: #eff2f7;
}
table.table td h2.table-avatar img {
  width: 40px;
  height: 40px;
}
div.dataTables_wrapper div.dataTables_length select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.table tbody tr:last-child {
  border-color: transparent;
}
.table td,
.table th {
  border-left: transparent;
  border-right: transparent;
  white-space: nowrap;
}
.table .white-space {
  white-space: initial;
}

.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
  visibility: visible;
  opacity: 1;
  margin-top: -1px;
  margin-right: 0;
}
.main-nav > li .submenu > li .submenu {
  left: 100%;
  top: 0;
  margin-top: 10px;
}

.main-nav li.has-submenu:hover > .submenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
  top: 20px;
  margin-left: -35px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
  border-color: transparent #fff #fff transparent;
}

/*-----------------
	34. Membership
-----------------------*/

.package-detail {
  background: #fff;
  border: 1px solid #ced4e6;
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
  border-radius: 10px;
  padding: 24px;
}
.package-detail h4 {
  font-weight: 600;
  font-size: 22px;
}
.package-detail p {
  font-size: 16px;
  margin-bottom: 10px;
}
.package-detail .package-price {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced4e6;
}
.package-detail .package-price span {
  font-weight: 400;
  font-size: 16px;
  color: #6b7385;
  margin-bottom: 0;
}
.package-feature ul {
  list-style: none;
  margin: 20px 0 30px 0;
  padding: 0;
  outline: none;
}
.package-feature ul li {
  margin-bottom: 14px;
  position: relative;
  font-size: 16px;
  color: #6b7385;
  font-weight: 600;
  padding-left: 25px;
}
.package-feature ul li:last-child {
  margin-bottom: 0;
}
.package-feature ul li.non-check::before {
  content: "\f057";
  color: #f5365c;
}
.package-feature ul li::before {
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #4b9f18;
  left: 0;
  position: absolute;
  top: 0;
}
.price-btn {
  background: #131135;
  border: 1px solid #131135;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  padding: 10px 20px;
}
.member-detail h4 {
  font-weight: 600;
  font-size: 22px;
  color: #e65425;
}
.member-detail h5 {
  margin-bottom: 5px;
}
.member-detail h3 {
  font-weight: 600;
  font-size: 22px;
  color: #e65425;
  margin-bottom: 2px;
}

.member-detail .expiry-on {
  font-size: 14px;
  color: #6b7385;
}
.member-detail .expiry-on span {
  color: #1b1b1b;
  font-weight: 500;
}
.member-detail .expiry-on span i {
  margin-right: 8px;
}
.member-detail .expiry {
  font-weight: 500;
  font-size: 16px;
}
.member-plan {
  margin-bottom: 25px;
}
.freelance-title {
  margin-bottom: 40px;
}
.freelance-title h3 {
  font-weight: 600;
  font-size: 26px;
}
.freelance-title p {
  font-size: 16px;
  color: #757575;
}
.change-plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fav .filled,
.fav i {
  color: #f5365c;
}
.fav i:hover {
  color: #f5365c;
  font-weight: 700;
}
.tab-imgcircle::before {
  background-color: #159c5b;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  content: "\f00c";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 9px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}
