.SectionAboutMission {

}

.SectionAboutMission .content {
    max-width: 1100px;
    margin: auto;
    text-align: center;

}

.SectionAboutMission .content  h2{
    font-size: 40px;
    color: #1B1B1B;
    margin-bottom: 17px;
    font-weight: 600;
    text-align: center;
}

.SectionAboutMission .content  p {
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #6F6F6F;
}

.SectionAboutMission .images {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.SectionAboutMission .images .img-item {
    flex-grow: 1;
    border-radius: 4px;
    flex-shrink: 1;
    flex-basis: 50%;
}

.SectionAboutMission .images .img-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}