.UserBudget {
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid #e8e8e8;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px #8E8DD01F;
}

.UserBudget .widget-main .title h4 {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: #161c2d;
    margin-bottom: 14px;
    text-align: center;
}

.UserBudget .widget-main .content {

}

.UserBudget .widget-main .content h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
    margin-bottom: 5px;
}

.UserBudget .widget-main .content p {
    color: #6B7385;
    margin: 0;
    text-align: center;
}

.UserBudget .widget-main .content .user-info {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 15px 0 0;
    margin-bottom: 24px;
}

.UserBudget .widget-main .content .user-info li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.UserBudget .widget-main .content .user-info li h6 {
    font-size: 14px;
    margin: 0;
    color: #6B7385;
    font-weight: 400;
}

.UserBudget .widget-main .content .user-info li h6 i {
    margin-right: 8px;
}

.UserBudget .widget-main .content .user-info li h5 {
    color: #1B1B1B;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}

.UserBudget .widget-main .content .send-invite {
    background-color: #E65425;
    border: 1px solid #E65425;
    width: 100%;
    border-radius: 6px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 47px;
    font-size: 16px;
    color: white;
    transition: 0.22s ease-in-out;
    text-transform: capitalize;
}

.UserBudget .widget-main .content .send-invite:hover {
    background-color: white;
    color: #E65425;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}




