.Register {
    min-height: 100vh;
    /* background-image: url("../../../assets/images/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 65px 0px;
}

.Register .register-content {
    min-height: 133px;
    width: 480px;
    border-radius: 10px;
    padding: 30px;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
    border: 1px solid #ddd;
}

.Register .register-header p {
    font-weight: 600;
    margin-bottom: 20px!important;
    font-size: 18px;
}

.Register .register-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.Register .register-header .register-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Register .register-header .register-options button {
    border: none;
    border: 1px solid #131135;
    padding: 7px 22px;
    border-radius: 4px;
    background-color: white!important;
    color: #131135;
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s;
}

.Register .register-header .register-options button:hover {
    background-color: #131135!important;
    color: white;
}

.Register .register-header .register-options .form-active {
    background-color: #131135!important;
    color: white;
} 


.Register .register-header .logo {
    width: 36%;
    margin-bottom: 20px;
}

.Register .register-header .logo img {
    width: 100%;
    object-fit: contain;
}


.Register .register-form .label-star {
    color: red;
}

.Register .register-form .focus-label {
    margin-bottom: 8px;
    font-weight: 600;
    color: #131135;
    font-size: 14px;
}

.Register .register-form .register-button {
    margin-bottom: 10px;
}


.Register .register-form .register-button button {
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
    border: 0.8px solid white;
    color: white;
    background-color: #E65425;
    border-radius: 6px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.Register .register-form .register-button button i {
    font-size: 24px;
    display: flex;

}

.Register .register-form .register-button button:hover {
    border: 0.8px solid #E65425 !important;
    color: #E65425 !important;
    background-color: white !important;
}


.Register .register-form .input-block {
    margin-bottom: 20px;
}

.Register .register-form .input-block.input-check .position-relative{
    display: flex;
    align-items: center;
    gap: 10px;
}

.Register .register-form .input-block.input-check a {
    color: #E65425;
    cursor: pointer;
    font-weight: 600;
}

.Register .register-form .input-block .checkmark {
    width: 20px;
    height: 20px;
    cursor: pointer;
}




.Register .register-form .actions {}

.Register .register-form .actions .action-line {
    position: relative;
    height: fit-content;


}

.Register .register-form .actions .action-line::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.5px;
    background-color: #ddd;
    top: calc(50% - 0.25px);
}

.Register .register-form .actions .action-line span {
    background-color: white;
    width: 65px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    font-size: 18px;
    opacity: 0.8;
}

.Register .register-form .actions .action-socials {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.Register .register-form .actions .action-socials .social-btn {
    flex-grow: 1;
    flex-basis: 33.3333%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    gap: 10px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.Register .register-form .actions .action-socials .social-btn .social-icon {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Register .register-form .actions .action-socials .social-btn .social-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Register .register-form .actions .action-options {
    display: flex;
    justify-content: space-between;
}

.Register .register-form .actions .action-options .signup-link {
    color: #E65425;
    cursor: pointer;
    font-weight: 600;
}

.Register .register-form .actions .action-options .forgot-password span {
    cursor: pointer;
    font-weight: 600;
    opacity: 0.8;
}

.Register .register-form .actions .action-options .forgot-password span:hover {
    opacity: 1;
}


.Register .register-form .form-input .input-block .password-icon {
    position: absolute;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.6;
}


.Register .register-form .form-input .input-block .password-icon:hover {
    opacity: 1;
}

.Register .register-form .form-input .input-block .password-icon i {
    font-size: 20px;
}